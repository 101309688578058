import { FC, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAppSelector } from '../../store/hooks';
import { UpdateAccountShipment, createShipmentApi } from '../../services';

import {
  AccountInterface,
  BusinessUnitInterface,
  CoordinatesInterface,
  DeliveryEnum,
  ItemInterface,
  PaymentModeId,
  PieceInterface,
  PreShipmentInterface,
  RangeDeclareDto,
  ShipmentInterface,
  ShipmentStatusEnum,
  ShippingMethodEnum,
  TrackingEnum,
} from '../../interfaces';
import {
  Card,
  MainPage,
  PageTitle,
  SenderForm,
  ConsigneeFormV2,
  AccountDetails,
  ShipmentFormV2,
  ShipmentMessageModal,
  ConfirmModalStatus,
  ShipmentPrintModal,
  ModalLoading,
} from '../../components';
import { ServiceEnum } from '../../interfaces/Services/ServicesEnum';
import { PackageTypeEnum } from '../../interfaces/Shipment/PackageTypeEnum';
import { WeightTypeEnum } from '../../interfaces/Shipment/WeightTypeEnum';
import { AccountShipmentDto } from '../../interfaces/Account/AccountInterfaces';
import { ScrollToError } from '../../components/Atoms/ScrollToFieldError';

const CreateShipmentV2: FC = () => {
  const rangeMinDeclareList = useAppSelector(
    (state) => state.inmutable.rangeDeclareList
  );
  const newPieceInitialValues = {
    category: '',
    height: '',
    width: '',
    length: '',
    weight: '',
    declaredValue: '0',
    amount: 1,
    packageType: '20',
  };

  const validationSchema = Yup.object().shape({
    sender: Yup.object().shape({
      fullName: Yup.string().required('Este campo es requerido'),
      abbreviationName: Yup.object().required('Este campo es requerido'),
      identificationNumber: Yup.string().required('Este campo es requerido'),
    }),
    consignee: Yup.object().shape({
      fullName: Yup.string().required('Este campo es requerido'),
      abbreviationName: Yup.object().required('Este campo es requerido'),
      identificationNumber: Yup.string().required('Este campo es requerido'),
      phoneNumber: Yup.string()
        .required('Este campo es requerido')
        .test({
          name: 'number',
          message: 'Debe tener un maximo de 7 caracteres',
          test: (number, parent) => {
            if (parent.parent.codePhoneNumber !== 'FIJO' && number.length > 7)
              return false;

            return true;
          },
        })
        .test({
          name: 'number',
          message: 'Debe tener un maximo de 10 caracteres',
          test: (number, parent) => {
            if (parent.parent.codePhoneNumber === 'FIJO' && number.length > 10)
              return false;

            return true;
          },
        }),
      codePhoneNumber: Yup.string().required('Este campo es requerido'),
      cellPhoneNumber: Yup.string()
        .test({
          name: 'number',
          message: 'Debe tener un maximo de 7 caracteres',
          test: (number, parent) => {
            if (
              parent.parent.codecellNumber !== 'FIJO' &&
              number !== undefined &&
              number?.length > 7
            )
              return false;

            return true;
          },
        })
        .test({
          name: 'number',
          message: 'Debe tener un maximo de 10 caracteres',
          test: (number, parent) => {
            if (
              parent.parent.codecellNumber === 'FIJO' &&
              number !== undefined &&
              number?.length > 10
            )
              return false;

            return true;
          },
        }),
      email: Yup.string()
        .email('Correo invalido. Ejemplo: tealca@tealca.com')
        .required('Este campo es requerido'),
      city: Yup.string().required('Este campo es requerido'),
      address: Yup.string().required('Este campo es requerido'),
      businessUnit: Yup.object().required('Este campo es requerido'),
      accountSelected: Yup.object().required('Este campo es requerido'),
      ShipmentType: Yup.string().required('Este campo es requerido'),
    }),
    shipment: Yup.object().shape({
      pieces: Yup.array()
        .test({
          name: 'pieces',
          message: 'Debe agregar al menos una pieza',
          test: (pieces) => (pieces?.length ?? 0) > 0,
        })
        .test({
          name: 'pieces',
          message: 'El total de las piezas debe ser menor 999kg',
          test: (pieces, parent) => {
            const sum =
              pieces?.reduce((acc, contact) => {
                return acc + contact.weight;
              }, 0) || 0;

            if (parent.parent.packageType === PackageTypeEnum.ABOUT)
              return true;

            return sum <= 999;
          },
        })
        .test({
          name: 'pieces',
          message: 'El total de las piezas debe ser menor 500g o 0.500kg',
          test: (pieces, parent) => {
            const sum =
              pieces?.reduce((acc, contact) => {
                return acc + contact.weight;
              }, 0) || 0;

            if (parent.parent.packageType === PackageTypeEnum.BOX) return true;

            return sum < 0.501;
          },
        }),
      valueDeclarate: Yup.string().when('isSafeKeeping', {
        is: (isSafeKeeping: boolean) => isSafeKeeping,
        then: () =>
          Yup.string()
            .required('Este campo es requerido')
            .test({
              name: 'declared',
              message: `El valor declarado no puede ser menor a ${minValue.toFixed(
                2
              )}$`,
              test: (value, parent) => {
                const totalChargedWeight =
                  parent.parent.pieces?.reduce((acc: number, contact: any) => {
                    return acc + contact.weight;
                  }, 0) || 0;

                let totalValue = 0;
                if (
                  (parent.parent.paymentModeID as PaymentModeId)?.toString() ===
                  PaymentModeId.COD?.toString()
                ) {
                  totalValue = minDeclaredValue(
                    totalChargedWeight ?? 0,
                    rangeMinDeclareList
                  );
                }
                setMinValue(totalValue);
                return parseFloat(value ? value : '0') >= totalValue;
              },
            }),
      }),
    }),
  });

  const minDeclaredValue = (weight: number, rangeList: RangeDeclareDto[]) => {
    return (
      rangeList.find((r) => r.minWeight < weight && weight <= r.maxWeight)
        ?.minDeclareValue ?? 0
    );
  };
  const [minValue, setMinValue] = useState(0);

  const user = useAppSelector((state) => state.user)!;
  let paymentModeid =
    user.paymentMethod != null && user.paymentMethod!?.length > 0
      ? user.paymentMethod![0]?.paymentModeID
      : PaymentModeId.CREDIT;

  const shipmentInitialValues = {
    service: ServiceEnum.CREDITO,
    paymentModeID: paymentModeid ?? (PaymentModeId.CREDIT as PaymentModeId),
    shippingMethod: ShippingMethodEnum.LAND,
    isSafeKeeping: paymentModeid?.toString() === PaymentModeId.COD.toString(),
    packageType: PackageTypeEnum.BOX,
    weightUnit: WeightTypeEnum.KG,
    pieces: [] as PieceInterface[],
    tracking: '',
    observations: '',
    valueDeclarate: '',
    accountBillToID: user!.paymentMethod![0].accountBillTo ?? undefined,
    items: [] as ItemInterface[],
    deliveryDistance: 0,
  };
  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  const senderInitialValues = {
    fullName: user.client?.accountFullName ?? '',
    abbreviationName: identificationTypes.find((t) =>
      t.abbreviationName === user.client?.abbreviationName
        ? user.client?.abbreviationName
        : 'V-'
    )!,
    identificationNumber: user.client?.identificationNumber ?? '',
  };
  const consigneeInitialValues = {
    abbreviationName: identificationTypes.find(
      (t) => t.abbreviationName === 'V-'
    )!,
    id: '',
    identificationNumber: '',
    fullName: '',
    codePhoneNumber: '',
    phoneId: '',
    phoneTypeId: '',
    phoneNumber: '',
    acceptsSMS: false,
    codeCellPhoneNumber: '',
    cellPhoneNumber: '',
    emailId: '',
    email: '',
    city: '',
    cityCode: '',
    postalCode: '',
    address: '',
    reference: '',
    reset: true,
    businessUnit: undefined as BusinessUnitInterface | undefined,
    accountSelected: undefined as AccountInterface | undefined,
    newAccount: undefined,
    ShipmentType: '10' as DeliveryEnum | undefined,
  };
  const [openModal, setOpenModal] = useState(false);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [shipmentModal, setShipmentModal] = useState(
    {} as PreShipmentInterface
  );
  const [status, setStatus] = useState(ConfirmModalStatus.CONFIRM);

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  return (
    <MainPage>
      <PageTitle title="Crear Envio" />

      <Formik
        initialValues={{
          sender: senderInitialValues,
          newPiece: newPieceInitialValues,
          consignee: consigneeInitialValues,
          shipment: shipmentInitialValues,
          shippingLocation: {
            name: '',
            code: '',
            address: '',
            postalCode: '',
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          let accountBillTo = user.paymentMethod?.filter(
            (x) =>
              x.paymentModeID.toString() ===
              values.shipment.paymentModeID.toString()
          )![0]?.accountBillTo;

          if (
            (accountBillTo === '00000000-0000-0000-0000-000000000000' ||
              accountBillTo === undefined) &&
            values.shipment.paymentModeID.toString() === PaymentModeId.CREDIT
          ) {
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle(
              'Comuniquese con el departamento de Soporte Técnico. No tiene cuenta para facturar asignada'
            );
            setLoading(true);
            return;
          }

          if (user.client?.businessUnit! === undefined) {
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle(
              'Comuniquese con el departamento de Soporte Técnico. No tiene tienda origen asignado.'
            );
            setLoading(true);
            return;
          }
          const shipmentRequest: ShipmentInterface[] = [
            {
              id: '',
              number: 0,
              accountBillToID:
                values.shipment.paymentModeID.toString() === PaymentModeId.COD
                  ? values.consignee.id === '' ||
                    values.consignee.id === undefined
                    ? undefined
                    : values.consignee.id
                  : accountBillTo === '00000000-0000-0000-0000-000000000000'
                  ? undefined
                  : accountBillTo,
              trackingDetails: values.shipment.tracking,
              pieces: values.shipment.pieces,
              observations: values.shipment.observations,
              totalPieces: values.shipment.pieces.length,
              totalWeight: values.shipment.pieces.reduce(
                (acc, cur) => acc + Number(cur.weight),
                0
              ),
              declaredValue: values.shipment.pieces.reduce(
                (acc, cur) => acc + Number(cur.declaredValue),
                0
              ),
              tracking: TrackingEnum.TRACKING,
              isHighValueCargo: false,
              service: values.shipment.service,
              deliveryType: values.consignee.ShipmentType as DeliveryEnum,
              shippingMethod: values.shipment.shippingMethod,
              creationUser: user.user?.login ?? 'ccw',
              shipper: user.client!,
              businessUnitOrigin: user.client?.businessUnit!,
              businessUnitConsignee: values.consignee.businessUnit!,
              sender: {
                fullName: values.sender.fullName,
                abbreviationId:
                  values.sender.abbreviationName.taxIdentificationTypeId,
                abbreviationName:
                  values.sender.abbreviationName.taxIdentificationTypeCode,
                identificationNumber: values.sender.identificationNumber,
              },
              consignee: {
                id: values.consignee.id,
                accountCode: '',
                agreementId: 0,
                cityCode: values.consignee.cityCode,
                taxIdentificationTypeID:
                  values.consignee.abbreviationName.taxIdentificationTypeId,
                abbreviationName:
                  values.consignee.abbreviationName.taxIdentificationTypeCode,
                identificationNumber: values.consignee.identificationNumber,
                accountFullName: values.consignee.fullName,
                fiscalAddress: values.consignee.address,
                reference: values.consignee.reference,
                listAccountPhone: [
                  {
                    countryId: '236',
                    acceptsSMS: false,
                    countryPhoneAccessCode: '+58',
                    phoneTypeId: '1',
                    phoneNumber:
                      (values.consignee.codePhoneNumber === 'FIJO'
                        ? ''
                        : values.consignee.codePhoneNumber) +
                      '' +
                      values.consignee.phoneNumber,
                  },
                  {
                    countryId: '236',
                    acceptsSMS: values.consignee.acceptsSMS,
                    countryPhoneAccessCode: '+58',
                    phoneTypeId: '2',
                    phoneNumber:
                      (values.consignee.codePhoneNumber === 'FIJO'
                        ? ''
                        : values.consignee.codeCellPhoneNumber) +
                      ' ' +
                      values.consignee.cellPhoneNumber,
                  },
                ],
                listAccountEmail: [
                  {
                    id: '',
                    emailTypeId: '2',
                    email: values.consignee.email,
                  },
                ],
                listAuthorizingAccount: [],
              },
              shippingLocation:
                values.shippingLocation.name !== ''
                  ? values.shippingLocation
                  : {
                      name: values.consignee.city,
                      address: values.consignee.address,
                      postalCode: values.consignee.postalCode,
                      coordinates:
                        values.consignee.businessUnit?.location.coordinates ??
                        ({} as CoordinatesInterface),
                    },
              isSED: false,
              isToHold: false,
              isFragile: false,
              isRepacke: false,
              isPreAlerted: false,
              isSafeKeeping: values.shipment.isSafeKeeping,

              status: ShipmentStatusEnum.ACTIVE,
              packageType: values.shipment.packageType,
              paymentModeID: values.shipment.paymentModeID,
            },
          ];

          setLoadingStatus(ConfirmModalStatus.PROCESSING);
          setLoadingTitle('Guardando...');
          setLoading(true);

          if (values.consignee.id !== undefined) {
            let account = {
              accountID: values.consignee.id,
              taxIdentificationTypeID:
                values.consignee?.abbreviationName?.taxIdentificationTypeId,
              identificationNumber: values.consignee.identificationNumber,
              accountFullName: values.consignee.fullName,
              accountPhoneID: values.consignee.phoneId,
              phoneTypeID:
                values.consignee.phoneTypeId === undefined ||
                values.consignee.phoneTypeId.length === 0
                  ? 1
                  : parseInt(values.consignee.phoneTypeId ?? '1'),
              phoneNumber:
                (values.consignee.codePhoneNumber === 'FIJO'
                  ? ''
                  : values.consignee.codePhoneNumber) +
                '' +
                values.consignee.phoneNumber,
              acceptsSMS: values.consignee.acceptsSMS,
              accountEmailID: values.consignee.emailId,
              email: values.consignee.email,
              fiscalAddress: values.consignee.address,
              updateUser: user.user?.login ?? 'ccw',
            } as AccountShipmentDto;

            await UpdateAccountShipment(account);
          }
          const newShipment = await createShipmentApi(shipmentRequest);

          if (
            newShipment.shipmentNumber !== null &&
            newShipment.shipmentNumber !== ''
          ) {
            resetForm();
            setShipmentModal(newShipment);
            setOpenModal(true);
            setStatus(ConfirmModalStatus.SUCCESS);
            setLoading(false);
          } else {
            setLoading(true);
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle(
              'Existe un error al crear la guia contacte a Soporte Tecnico.'
            );
          }
        }}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-1 flex-col gap-16 w-full"
          >
            <div className="flex flex-1 flex-col xl:flex-row gap-16">
              {/* Shipper */}
              <Card title="Datos del Remitente">
                <div className="flex flex-1 flex-col p-4 gap-12">
                  {/* Sender form */}
                  <div className="sm:px-8">
                    <SenderForm formik={formik} />
                  </div>
                  {/* Shipper details */}
                  <div className="flex flex-1 flex-col border rounded px-4 pt-2 pb-6 gap-4 shadow bg-white">
                    <div className="flex flex-1 font-semibold text-sm text-gray-700">
                      Detalles del Remitente
                    </div>

                    <hr className="-mt-2" />

                    <AccountDetails {...user.client!} />
                  </div>
                </div>
              </Card>

              {/* Consignee */}
              <Card title="Datos del Destinatario">
                <div className="flex flex-1 flex-col py-4 mb-6 px-4 sm:px-12">
                  <ConsigneeFormV2 formik={formik} />
                </div>
              </Card>
            </div>

            <div className="flex flex-1 max-w-full">
              {/* Shipment */}
              <Card title="Datos del Envío" className="max-w-full">
                <div className="flex flex-1 flex-col px-4 sm:px-12 py-4 mb-6">
                  <ShipmentFormV2 formik={formik} />
                </div>
              </Card>
            </div>
            {(shipmentModal?.shipmentNumber?.length ?? 0) > 0 && (
              <ShipmentMessageModal
                open={openModal}
                status={status}
                shipment={shipmentModal}
                setOpen={setOpenModal}
                onPrint={() => {
                  setOpenPrintModal(true);
                  setOpenModal(false);
                }}
              />
            )}

            {(shipmentModal?.shipmentNumber?.length ?? 0) > 0 &&
              openPrintModal && (
                <ShipmentPrintModal
                  open={openPrintModal}
                  shipment={shipmentModal}
                  setOpen={setOpenPrintModal}
                  onPrint={() => {}}
                />
              )}

            <ModalLoading
              open={loading}
              title={loadingTitle}
              status={loadingStatus}
              setOpen={setLoading}
              onPrint={() => {}}
            />

            {formik.isSubmitting && (
              <ScrollToError
                formikCount={Object.keys(formik.errors).length > 0}
                data={formik.errors}
              />
            )}
          </form>
        )}
      </Formik>
    </MainPage>
  );
};

export default CreateShipmentV2;
