import { FC, useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { PreShipmentInterface } from '../../interfaces';
import { PrimaryButton } from '../Atoms/Buttons';
import { ModalWidthFull } from '../Atoms/ModalWidthFull';
import { DowloadShipments } from '../../services/ShipmentServices';
import logo from '../../assets/images/TealcaLogo.png';
import LoadingIcon from '../LoadingIcon';

interface PshipmentPrintModalProps {
  open: boolean;
  shipment: PreShipmentInterface;
  onPrint: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShipmentPrintModal: FC<PshipmentPrintModalProps> = ({
  open,
  shipment,
  onPrint,
  setOpen,
}) => {
  const [pdfUrl, setPdfUrl] = useState('');

  useEffect(() => {
    const fetchShipments = () => {
      setPdfUrl('');
      if (
        (shipment.shipmentNumber !== undefined &&
          shipment.shipmentNumber !== '' &&
          shipment.shipmentNumber !== null) ||
        (shipment.number !== undefined &&
          shipment.number !== 0 &&
          shipment.number !== null)
      ) {
        DowloadShipments(
          shipment.shipmentNumber ?? shipment.number.toString() ?? ''
        )
          .then((response) => response.blob())
          .then((blob) => {
            const file = window.URL.createObjectURL(blob);
            setPdfUrl(file);
          });
      }
    };

    fetchShipments();
  }, [shipment]);

  return (
    <ModalWidthFull
      open={open}
      setOpen={() => {}}
      className="flex flex-1 mx-4 my-8"
      style={{ maxWidth: '90rem', height: '50rem' }}
    >
      <div className="flex flex-1 flex-col">
        {/* Header */}
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-xl font-bold">
            <span className="text-gray-700 hidden sm:inline">
              Impresión de la Guía:{' '}
            </span>
            <span className="text-main-500">{shipment.shipmentNumber}</span>
          </h2>

          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-main-500"
              onClick={() => setOpen(false)}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>

        <hr className="border-gray-300 my-3" />

        {pdfUrl.length > 0 && (
          <embed
            src={pdfUrl}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        )}

        {pdfUrl.length === 0 && (
          <div className="flex flex-1 min-h-full flex-col items-center justify-center content-center">
            <img
              src={logo}
              className="animate-pulse -mt-11 -ml-1"
              alt="Tealca logo"
              width="60"
              height="60"
              style={{ position: 'absolute' }}
            />
            <LoadingIcon size="7rem" />
            <label className="mt-4 text-center animate-pulse font-bold ">
              Cargando...
            </label>
          </div>
        )}
        <hr className="border-gray-300 my-6" />
        {/* Footer */}
        <div className="flex flex-1 justify-between items-center">
          <PrimaryButton onClick={() => setOpen(false)}>
            <span className="w-20">Cerrar</span>
          </PrimaryButton>
        </div>
      </div>
    </ModalWidthFull>
  );
};
