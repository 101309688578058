import { FC, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAppSelector } from '../../store/hooks';
import { createPreShipment } from '../../services';

import {
  AccountInterface,
  BusinessUnitInterface,
  CoordinatesInterface,
  DeliveryEnum,
  ItemInterface,
  PaymentModeId,
  PieceInterface,
  PreShipmentInterface,
  RangeDeclareDto,
  ShipmentStatusEnum,
  ShippingMethodEnum,
} from '../../interfaces';
import {
  Card,
  MainPage,
  PageTitle,
  SenderForm,
  ConsigneeForm,
  AccountDetails,
  PreShipmentForm,
  PresipmentMessageModal,
  ConfirmModalStatus,
  ModalLoading,
} from '../../components';
import { ServiceEnum } from '../../interfaces/Services/ServicesEnum';
import { PackageTypeEnum } from '../../interfaces/Shipment/PackageTypeEnum';
import { WeightTypeEnum } from '../../interfaces/Shipment/WeightTypeEnum';
import { PresipmentImpresionModal } from '../../components/Preshipment/PreshipmentImpresionModal';
import { ScrollToErrorPreShipment } from '../../components/Atoms/ScrollToFieldError';

const CreatePreShipment: FC = () => {
  const user = useAppSelector((state) => state.user)!;
  const rangeMinDeclareList = useAppSelector(
    (state) => state.inmutable.rangeDeclareList
  );

  const newPieceInitialValues = {
    category: '',
    height: '',
    width: '',
    length: '',
    weight: '',
    declaredValue: '0',
    amount: 1,
    packageType: '20',
  };

  const validationSchema = Yup.object().shape({
    sender: Yup.object().shape({
      fullName: Yup.string().required('Este campo es requerido'),
      abbreviationName: Yup.object().required('Este campo es requerido'),
      identificationNumber: Yup.string().required('Este campo es requerido'),
    }),
    consignee: Yup.object().shape({
      fullName: Yup.string().required('Este campo es requerido'),
      abbreviationName: Yup.object().required('Este campo es requerido'),
      identificationNumber: Yup.string().required('Este campo es requerido'),
      phoneNumber: Yup.string().required('Este campo es requerido'),
      codePhoneNumber: Yup.string().required('Este campo es requerido'),
      email: Yup.string()
        .email('Correo invalido. Ejemplo: tealca@tealca.com')
        .required('Este campo es requerido'),
      city: Yup.string().required('Este campo es requerido'),
      address: Yup.string().required('Este campo es requerido'),
      businessUnit: Yup.object().required('Este campo es requerido'),
      accountSelected: Yup.object().required('Este campo es requerido'),
      ShipmentType: Yup.string().required('Este campo es requerido'),
    }),
    preShipment: Yup.object().shape({
      pieces: Yup.array()
        .test({
          name: 'pieces',
          message: 'Debe agregar al menos una pieza',
          test: (pieces) => (pieces?.length ?? 0) > 0,
        })
        .test({
          name: 'pieces',
          message: 'El total de las piezas debe ser menor 999kg',
          test: (pieces, parent) => {
            const sum =
              pieces?.reduce((acc, contact) => {
                return acc + contact.weight;
              }, 0) || 0;
            if (parent.parent.packageType === PackageTypeEnum.ABOUT)
              return true;

            return sum <= 999;
          },
        })
        .test({
          name: 'pieces',
          message: 'El total de las piezas debe ser menor 500g o 0.500kg',
          test: (pieces, parent) => {
            const sum =
              pieces?.reduce((acc, contact) => {
                return acc + contact.weight;
              }, 0) || 0;

            if (parent.parent.packageType === PackageTypeEnum.BOX) return true;

            return sum < 0.501;
          },
        }),
      valueDeclarate: Yup.string().when('isSafeKeeping', {
        is: (isSafeKeeping: boolean) => isSafeKeeping,
        then: () =>
          Yup.string()
            .required('Este campo es requerido')
            .test({
              name: 'declared',
              message: `El valor declarado no puede ser menor a ${minValue.toFixed(
                2
              )}$`,
              test: (value, parent) => {
                const totalChargedWeight =
                  parent.parent.pieces?.reduce((acc: number, contact: any) => {
                    return acc + contact.weight;
                  }, 0) || 0;

                let totalValue = 0;
                if (
                  (parent.parent.paymentModeID as PaymentModeId)?.toString() ===
                  PaymentModeId.COD?.toString()
                ) {
                  totalValue = minDeclaredValue(
                    totalChargedWeight ?? 0,
                    rangeMinDeclareList
                  );
                }
                setMinValue(totalValue);
                return parseFloat(value ? value : '0') >= totalValue;
              },
            }),
      }),
    }),
  });
  const [minValue, setMinValue] = useState(0);
  const minDeclaredValue = (weight: number, rangeList: RangeDeclareDto[]) => {
    return (
      rangeList.find((r) => r.minWeight < weight && weight <= r.maxWeight)
        ?.minDeclareValue ?? 0
    );
  };

  const identificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const senderInitialValues = {
    fullName: user.client?.accountFullName ?? '',
    abbreviationName: identificationTypes.find((t) =>
      t.abbreviationName === user.client?.abbreviationName
        ? user.client?.abbreviationName
        : 'V-'
    )!,
    identificationNumber: user.client?.identificationNumber ?? '',
  };
  const consigneeInitialValues = {
    abbreviationName: identificationTypes.find(
      (t) => t.abbreviationName === 'V-'
    )!,
    identificationNumber: '',
    fullName: '',
    codePhoneNumber: '',
    phoneNumber: '',
    acceptsSMS: false,
    codeCellPhoneNumber: '',
    cellPhoneNumber: '',
    email: '',
    city: '',
    cityCode: '',
    postalCode: '',
    address: '',
    reference: '',
    reset: true,
    businessUnit: undefined as BusinessUnitInterface | undefined,
    accountSelected: undefined as AccountInterface | undefined,
    newAccount: undefined,
    ShipmentType: '10' as DeliveryEnum | undefined,
  };

  const [openModal, setOpenModal] = useState(false);
  const [preShipmentModal, setPreShipmentModal] = useState(
    {} as PreShipmentInterface
  );
  const [status, setStatus] = useState(ConfirmModalStatus.CONFIRM);
  const [openModalPrint, setOpenModalPrint] = useState(false);

  let [loading, setLoading] = useState(false);
  let [loadingStatus, setLoadingStatus] = useState(
    ConfirmModalStatus.PROCESSING
  );
  let [loadingTitle, setLoadingTitle] = useState('Cargando...');

  let paymentModeid =
    user.paymentMethod != null && user.paymentMethod!?.length > 0
      ? user.paymentMethod![0]?.paymentModeID
      : PaymentModeId.CREDIT;

  const preShipmentInitialValues = {
    service: ServiceEnum.CREDITO,
    paymentModeID: paymentModeid ?? (PaymentModeId.CREDIT as PaymentModeId),
    shippingMethod: ShippingMethodEnum.LAND,
    isSafeKeeping: paymentModeid?.toString() === PaymentModeId.COD.toString(),
    packageType: PackageTypeEnum.BOX,
    weightUnit: WeightTypeEnum.KG,
    pieces: [] as PieceInterface[],
    observations: '',
    valueDeclarate: '',
    tracking: '',
    items: [] as ItemInterface[],
    deliveryDistance: 0,
  };

  return (
    <MainPage>
      <PageTitle title="Crear Preguía" />

      <Formik
        initialValues={{
          sender: senderInitialValues,
          newPiece: newPieceInitialValues,
          consignee: consigneeInitialValues,
          preShipment: preShipmentInitialValues,
          shippingLocation: {
            name: '',
            code: '',
            address: '',
            postalCode: '',
            coordinates: {
              lat: 0,
              lng: 0,
            },
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          //resetForm();

          if (user.client?.businessUnit! === undefined) {
            setLoadingStatus(ConfirmModalStatus.ERROR);
            setLoadingTitle(
              'Comuniquese con el departamento de Soporte Técnico. No tiene tienda origen asignado.'
            );
            setLoading(true);
            return;
          }

          const preShipment: PreShipmentInterface[] = [
            {
              id: '',
              number: 0,
              customerCode: user.client!.accountCode,
              pieces: values.preShipment.pieces,
              observations: values.preShipment.observations,
              totalPieces: values.preShipment.pieces.length,
              totalWeight: values.preShipment.pieces.reduce(
                (acc, cur) => acc + Number(cur.weight),
                0
              ),
              declaredValue: values.preShipment.pieces.reduce(
                (acc, cur) => acc + Number(cur.declaredValue),
                0
              ),

              service: values.preShipment.service,
              deliveryType:
                values.consignee.ShipmentType ?? DeliveryEnum.OFFICE,
              shippingMethod: values.preShipment.shippingMethod,
              shipper: user.client!,
              businessUnitOrigin: user.businessUnit!,
              businessUnitConsignee: values.consignee.businessUnit!,
              sender: {
                fullName: values.sender.fullName,
                abbreviationName:
                  values.sender.abbreviationName.taxIdentificationTypeCode,
                identificationNumber: values.sender.identificationNumber,
              },
              consignee: {
                id: '',
                accountCode: '',
                agreementId: 0,
                cityCode: values.consignee.cityCode,
                abbreviationName:
                  values.consignee.abbreviationName.taxIdentificationTypeCode,
                identificationNumber: values.consignee.identificationNumber,
                accountFullName: values.consignee.fullName,
                fiscalAddress: values.consignee.address,
                listAccountPhone: [
                  {
                    countryId: '236',
                    acceptsSMS: false,
                    countryPhoneAccessCode: '+58',
                    phoneTypeId: '1',
                    phoneNumber:
                      (values.consignee.codePhoneNumber === 'FIJO'
                        ? ''
                        : values.consignee.codePhoneNumber) +
                      '' +
                      values.consignee.phoneNumber,
                  },
                  {
                    countryId: '236',
                    acceptsSMS: values.consignee.acceptsSMS,
                    countryPhoneAccessCode: '+58',
                    phoneTypeId: '2',
                    phoneNumber:
                      (values.consignee.codePhoneNumber === 'FIJO'
                        ? ''
                        : values.consignee.codeCellPhoneNumber) +
                      '' +
                      values.consignee.phoneNumber,
                  },
                ],
                listAccountEmail: [
                  {
                    id: '',
                    emailTypeId: '2',
                    email: values.consignee.email,
                  },
                ],
                listAuthorizingAccount: [],
              },
              shippingLocation: {
                name: values.consignee.city,
                address: values.consignee.address,
                postalCode: values.consignee.postalCode,
                coordinates:
                  values.consignee.businessUnit?.location.coordinates ??
                  ({} as CoordinatesInterface),
              },

              isSED: false,
              isToHold: false,
              isFragile: false,
              isRepacke: false,
              isPreAlerted: false,
              isSafeKeeping: values.preShipment.isSafeKeeping,
              status: ShipmentStatusEnum.ACTIVE,
              packageType: values.preShipment.packageType,
              paymentModeID: values.preShipment.paymentModeID,
            },
          ];

          setLoadingStatus(ConfirmModalStatus.PROCESSING);
          setLoadingTitle('Guardando...');
          setLoading(true);

          const newPreShipment = await createPreShipment(preShipment);

          if (
            newPreShipment.shipmentNumber !== null &&
            newPreShipment.shipmentNumber !== ''
          ) {
            resetForm();
            setPreShipmentModal(newPreShipment);
            setOpenModal(true);
            setStatus(ConfirmModalStatus.SUCCESS);
            setLoading(false);
          } else {
            setLoading(false);
            setOpenModal(true);
            setStatus(ConfirmModalStatus.ERROR);
          }
        }}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-1 flex-col gap-16 w-full"
          >
            <div className="flex flex-1 flex-col xl:flex-row gap-16">
              {/* Shipper */}
              <Card title="Datos del Remitente">
                <div className="flex flex-1 flex-col p-4 gap-12">
                  {/* Sender form */}
                  <div className="sm:px-8">
                    <SenderForm formik={formik} />
                  </div>

                  {/* Shipper details */}
                  <div className="flex flex-1 flex-col border rounded px-4 pt-2 pb-6 gap-4 shadow bg-white">
                    <div className="flex flex-1 font-semibold text-sm text-gray-700">
                      Detalles del Remitente
                    </div>

                    <hr className="-mt-2" />

                    <AccountDetails {...user.client!} />
                  </div>
                </div>
              </Card>

              {/* Consignee */}
              <Card title="Datos del Destinatario">
                <div className="flex flex-1 flex-col py-4 mb-6 px-4 sm:px-12">
                  <ConsigneeForm formik={formik} />
                </div>
              </Card>
            </div>

            <div className="flex flex-1 max-w-full">
              {/* Shipment */}
              <Card title="Datos del Envío" className="max-w-full">
                <div className="flex flex-1 flex-col px-4 sm:px-12 py-4 mb-6">
                  <PreShipmentForm formik={formik} />
                </div>
              </Card>
            </div>
            <PresipmentMessageModal
              open={openModal}
              status={status}
              shipment={preShipmentModal}
              setOpen={setOpenModal}
              onPrint={() => {
                setOpenModalPrint(true);
                setOpenModal(false);
              }}
            />
            {(preShipmentModal?.shipmentNumber?.length ?? 0) > 0 &&
              openModalPrint && (
                <PresipmentImpresionModal
                  open={openModalPrint}
                  shipment={preShipmentModal}
                  setOpen={setOpenModalPrint}
                  onPrint={() => {}}
                />
              )}

            <ModalLoading
              open={loading}
              title={loadingTitle}
              status={loadingStatus}
              setOpen={setLoading}
              onPrint={() => {}}
            />

            {formik.isSubmitting && (
              <ScrollToErrorPreShipment
                formikCount={Object.keys(formik.errors).length > 0}
                data={formik.errors}
              />
            )}
          </form>
        )}
      </Formik>
    </MainPage>
  );
};

export default CreatePreShipment;
